/**
 * @module
 */

import BbrInfo from "../BbrInfo.js"

export default class BbrEnhedInfo extends BbrInfo {
  constructor(options) {
    if (options.logger)
      options.logger.warn("Septima.Search.Datafordeler.BbrEnhedInfo er deprecated. Brug Septima.Search.Datafordeler.BbrInfo i stedet")
    else
      // eslint-disable-next-line no-console
      if (console && console.warn) {
        try {
          // eslint-disable-next-line no-console
          console.warn("Septima.Search.Datafordeler.BbrEnhedInfo er deprecated. Brug Septima.Search.Datafordeler.BbrInfo i stedet")
          // eslint-disable-next-line no-empty
        }catch(e) {}
      }

    super(options)
  }
}