/**
 * @module
 */

import DetailsHandlerDef from "../../details/DetailsHandlerDef.js"
import EnhedsSearcher from "./EnhedsSearcher.js"
import DetailItemsList from "../../details/DetailItemsList.js"
import DawaSearcher from "../DawaSearcher.js"
import {fetch2} from "../../utils.js"
import BygningsSearcher from "./BygningsSearcher.js"
import EjendomsSearcher from "./EjendomsSearcher.js"
/**
 * Viser BbrEnhedInfo for ejerlejlighed(daf.ejl), husnummer/adresse og bbr enhed (daf.bbr_enhed)
 * @extends module:js/details/DetailsHandlerDef
 * @example <caption>YAML Declaration:</caption>
 *   _type: datafordeler.BbrEnhedInfo
 *   _options:
 *     fetcher:
 *       _type: datafordeler.Fetcher
 *       _options:
 *         ...
 **/
export default class BbrInfo extends DetailsHandlerDef {
  /**
   * @param {Object} options
   * @param {Object} [options.fetcher] datafordeler.fetcher One of fetcher or ejendomssearcher 
   * @param {Object} [options.ejendomssearcher] datafordeler.ejdSearcher One of fetcher or ejendomssearcher
   **/  constructor(options = {}) {
    if (!options.ejendomssearcher && !options.fetcher)
      throw new Error("BbrInfo called without options.ejendomssearcher or options.fetcher")

    let defaultOptions = {}

    super(Object.assign(defaultOptions, options))

    this.handlerFunction = this.myHandler
    this.isApplicableFunction = (result) => {
      return ((result.typeId === "ejl" ||
        result.typeId === "enhedsadresse" ||
        result.typeId === "adresse" ||
        result.typeId === "bbr_enhed") ||
        result.typeId === "bbr_bygning" )
    }

    if (options.fetcher) {
      this.fetcher = options.fetcher
    }
    if (options.ejendomssearcher) {
      this.ejendomsSearcher = options.ejendomssearcher
      this.fetcher = this.ejendomsSearcher.fetcher
    } else {
      this.ejendomsSearcher = new EjendomsSearcher({fetcher: this.fetcher})
    }

    this.bygningsSearcher = null
    if (options.bygningssearcher)
      this.bygningsSearcher = options.bygningssearcher
    else
      this.bygningsSearcher = new BygningsSearcher({fetcher: this.fetcher})

    if (options.enhedssearcher)
      this.enhedsSearcher = options.enhedssearcher
    else
      this.enhedsSearcher = new EnhedsSearcher({fetcher: this.fetcher})

    this.configuredButtonText = null
    if (options.buttonText)
      this.configuredButtonText = options.buttonText
  }

  getbuttonText(result) {
    if (this.configuredButtonText) {
      return this.configuredButtonText
    } else {
      let buttonText = 'BBR-information'
      if (result.typeId === "bbr_enhed")
        buttonText = 'Om enheden'
      else if (result.typeId === "bbr_bygning")
        buttonText = 'Om bygningen'
      return buttonText
    }
  }

  async myHandler(result) {
    let source = result.source
    let typeId = result.typeId
    if (source === "DAF" && typeId === "bbr_bygning")
      return await this.getBygningsDetails(result)
    else
      return await this.getEnhedsDetails(result)
  }


  async getEnhedsDetails(result) {

    let items = []
    let bbrResponse
    let bbrEnheder
    if (result.typeId === 'enhedsadresse') {
      bbrResponse = await this.fetcher.read("bbr", "bbr", "enhed", { AdresseIdentificerer: result.id }, this.getLogger())
      if (bbrResponse.length == 0) {
        return [{
          type: "labelvalue",
          label: "BBR-info",
          value: "Ingen enhed fundet på adressen"
        }]
      } else {
        bbrEnheder = bbrResponse
      }
    } else if (result.typeId === 'adresse') {
      bbrEnheder = await this.getBbrObjectsForHusnummer(result.id)
      if (bbrEnheder.length == 0) {
        return [{
          type: "labelvalue",
          label: "BBR-info",
          value: "Ingen enheder fundet på husnummeret"
        }]
      }
    } else if (result.typeId === 'ejl') {
      bbrResponse = await this.fetcher.read("bbr", "bbr", "enhed", { BFENummer: result.id }, this.getLogger())
      if (bbrResponse.length == 0) {
        return [{
          type: "labelvalue",
          label: "BBR-info",
          value: "Ingen enhed fundet for lejligheden"
        }]
      } else {
        bbrEnheder = bbrResponse
      }
    } else if (result.typeId === 'bbr_enhed') {
      bbrEnheder = [result.data]
    }

    if (bbrEnheder.length == 1) {
      let bbrObject = bbrEnheder[0]

      let omenhedenSectionPromise = this.getOmEnhedenSection(bbrObject)

      let enhedsResultPromise
      if (result.typeId != 'bbr_enhed') {
        enhedsResultPromise = this.enhedsSearcher.get( bbrObject.id_lokalId, "bbr_enhed")
      }

      let bygningsResultPromise
      if (this.bygningsSearcher) {
        let bygningsId = bbrObject.bygning
        bygningsResultPromise = this.bygningsSearcher.get(bygningsId, "bbr_bygning")
      }

      items = [...await omenhedenSectionPromise]

      if (result.typeId == 'bbr_enhed') {
        let arealerSectionPromise = this.getEnhedsArealerSection(bbrObject)
        let badSectionPromise = this.getEnhedsBadSection(bbrObject)
        let toiletSectionPromise = this.getEnhedsToiletSection(bbrObject)
        let koekkenSectionPromise = this.getEnhedsKoekkenSection(bbrObject)
        items.push(...await arealerSectionPromise)
        items.push(...await badSectionPromise)
        items.push(...await toiletSectionPromise)
        items.push(...await koekkenSectionPromise)
      } else {
        items.push( {
          type: "result",
          label: "BBR-enhed",
          result: await enhedsResultPromise
        })
      }

      if (bygningsResultPromise) {
        let bygningsResult = await bygningsResultPromise
        if (bygningsResult) {
          items.push({
            type: "result",
            label: "BBR-bygning",
            result: bygningsResult
          })
        }
      }

    } else {
      let detailItemsList = new DetailItemsList({
        header: "BBR-enheder",
        name: "bbrenheder"
      })
      for (let bbrObject of bbrEnheder) {
        let enhedsResult = await this.enhedsSearcher.get( bbrObject.id_lokalId, "bbr_enhed")
        detailItemsList.append({
          type: "result",
          result: enhedsResult
        })
      }
      items.push(detailItemsList.asItem())
      if (result.typeId == 'adresse' && this.bygningsSearcher) {
        let bygningsId = bbrEnheder[0].bygning
        let bygningsResultPromise = this.bygningsSearcher.get(bygningsId, "bbr_bygning")
        let bygningsResult = await bygningsResultPromise
        
        if (bygningsResult) {
          items.push({
            type: "result",
            label: "BBR-bygning",
            result: bygningsResult
          })
        }
      }
    }
    return items
  }

  async getBygningsDetails(bygningsResult) {
    let items = []
    let bbrBygningResponse = bygningsResult.data

    let beskrivelsesSectionsPromise = this.getBygningsBeskrivelsesSections(bbrBygningResponse)
    //let getEtageListeSectionsPromise = this.getEtageListeSections(bbrBygningResponse)
    let forsyningsSectionPromise = this.getBygningsForsyningsSection(bbrBygningResponse)
    //let energiMaerkeSectionPromise = this.getBygningsEnergiMaerkeSection(bbrBygningResponse)
    let arealerSectionPromise = this.getBygningsArealerSection(bbrBygningResponse)
    let ejendomsSectionPromise = this.getBygningsEjendomsSection(bbrBygningResponse)
    //let boligerVirksomhederSectionPromise = this.getBoligerVirksomhederSection(bbrBygningResponse)
    let bbrKommuneLinksPromise = this.getBbrKommuneLinks(bbrBygningResponse)

    items = [
      ...await beskrivelsesSectionsPromise,
      ...await arealerSectionPromise,
      ...await forsyningsSectionPromise,
      //...await energiMaerkeSectionPromise,
      ...await ejendomsSectionPromise,
      //...await boligerVirksomhederSectionPromise,
      ...await bbrKommuneLinksPromise]
    return items
  }

  async getBygningsBeskrivelsesSections(bbrBygningResponse) {
    //Skal returnere to resultlists
    let items = []

    //Første resultlist
    //https://github.com/Septima/septima-search/issues/366#issuecomment-642627030
    let detailItemsList = new DetailItemsList({
      header: "Bygningen",
      name: "bygning_adresse_anvendelse"
    })

    let adgangsadressebetegnelse = ""
    try {
      let husnummerId = bbrBygningResponse.husnummer
      let dawaResult = await this.dawaSearcher.get(husnummerId, "adresse")
      adgangsadressebetegnelse = " - " + dawaResult.title
      // eslint-disable-next-line no-empty
    } catch (error) {
    }

    detailItemsList.append({
      type: "labelvalue",
      value: `BYG: ${bbrBygningResponse.byg007Bygningsnummer ? bbrBygningResponse.byg007Bygningsnummer : '(Intet nummer)'}  ${adgangsadressebetegnelse}`
    })

    detailItemsList.append({
      type: "labelvalue",
      value: "(" + bbrBygningResponse.byg021BygningensAnvendelse + ") " + this.fetcher.findBbrKode("BygAnvendelse", bbrBygningResponse.byg021BygningensAnvendelse)
    })


    items.push(detailItemsList.asItem())

    //Anden resultlist
    //https://github.com/Septima/septima-search/issues/366#issuecomment-644699466
    detailItemsList = new DetailItemsList({
      header: "Beskrivelse",
      name: "bygning_info"
    })

    let anvendelse = this.fetcher.findBbrKode("BygAnvendelse", bbrBygningResponse.byg021BygningensAnvendelse)
    let bebyggetAreal = bbrBygningResponse.byg041BebyggetAreal
    let beskrivelse = anvendelse + (bebyggetAreal ? " - " +bebyggetAreal + "m2" : "")
    detailItemsList.append({
      type: "labelvalue",
      value:  beskrivelse
    })
    
    let etager = bbrBygningResponse.byg054AntalEtager
    if (etager) {
      detailItemsList.append({
        type: "labelvalue",
        label: "Etager",
        value: etager
      })
    }

    let opfoerselsaar = bbrBygningResponse.byg026Opførelsesår
    if (opfoerselsaar) {
      detailItemsList.append({
        type: "labelvalue",
        label: "Opførelsesår",
        value: opfoerselsaar
      })
    }

    let tilbygningsaar = bbrBygningResponse.byg027OmTilbygningsår
    if (tilbygningsaar) {
      detailItemsList.append({
        type: "labelvalue",
        label: "Til/ombygningsår",
        value: tilbygningsaar
      })
    }

    let tagdaekningsMaterialeKode = bbrBygningResponse.byg033Tagdækningsmateriale
    let tagdaekningsMaterialeTekst = this.fetcher.findBbrKode("Tagdaekningsmateriale", tagdaekningsMaterialeKode)
    if (tagdaekningsMaterialeTekst) {
      detailItemsList.append({
        type: "labelvalue",
        label: "Tag",
        value: tagdaekningsMaterialeTekst
      })
    }

    let ydervaegsMaterialeKode = bbrBygningResponse.byg032YdervæggensMateriale
    let ydervaegsMaterialeTekst = this.fetcher.findBbrKode("YdervaeggenesMateriale", ydervaegsMaterialeKode)
    if (ydervaegsMaterialeTekst) {
      detailItemsList.append({
        type: "labelvalue",
        label: "Ydervægge",
        value: ydervaegsMaterialeTekst
      })
    }
    items.push(detailItemsList.asItem())

    return items
  }

  async getEtageListeSections(bbrBygningResponse) {

    let items = []
    let detailItemsList = new DetailItemsList({
      header: "Etager",
      name: "bygning_etager"
    })

    bbrBygningResponse.etageList.forEach(function (e) {
      if (e.etage.status === "6") {
        let text = ""
        if (e.etage.eta025Etagetype === "0") { //0 - Regulær etage
          text = "Regulær etage"
        } else if (e.etage.eta025Etagetype === "1") { //1 - Tagetage
          text = "Tagetage"
          if (e.etage.eta020SamletArealAfEtage)
            text = text + " - samlet areal: " + e.etage.eta020SamletArealAfEtage + "m2"
          if (e.etage.eta021ArealAfUdnyttetDelAfTagetage)
            text = text + " ,udnyttet areal: " + e.etage.eta021ArealAfUdnyttetDelAfTagetage + "m2"
        } else if (e.etage.eta025Etagetype === "2") { //2 - Kælder
          text = "Kælder"
          if (e.etage.eta020SamletArealAfEtage)
            text = text + " - samlet areal: " + e.etage.eta020SamletArealAfEtage + "m2"
          if (e.etage.eta022Kælderareal)
            text = text + " ,heraf kælderareal: " + e.etage.eta022Kælderareal + "m2"
        }
        detailItemsList.append({
          type: "labelvalue",
          label: e.etage.eta006BygningensEtagebetegnelse,
          value: text
        })
      }
    })
    items.push(detailItemsList.asItem())

    return items
  }

  async getBygningsForsyningsSection(bbrBygningResponse) {
    let forsyningsItems = []

    let detailItemsList = new DetailItemsList({
      header: "Forsyning",
      name: "bygning_forsyning"
    })

    let byg056Varmeinstallation = bbrBygningResponse.byg056Varmeinstallation
    let varmeinstallationTekst = this.fetcher.findBbrKode("BygVarmeinstallation", byg056Varmeinstallation)
    detailItemsList.append({
      type: "labelvalue",
      label: "Varme",
      value: varmeinstallationTekst
    })

    let grundeResponse = await this.fetcher.read("bbr", "bbr", "grund", { id: bbrBygningResponse.grund }, this.getLogger())

    if (bbrBygningResponse.byg030Vandforsyning) {
      let byg030Vandforsyning = bbrBygningResponse.byg030Vandforsyning // Eks: 1
      let vandforsyningsTekst = this.fetcher.findBbrKode("BygVandforsyning", byg030Vandforsyning)
      detailItemsList.append({
        type: "labelvalue",
        label: "Vand",
        value: vandforsyningsTekst
      })
    } else if (grundeResponse) {
      let gru009Vandforsyning = grundeResponse[0].gru009Vandforsyning // Eks: 1
      let vandforsyningsTekst = this.fetcher.findBbrKode("GruVandforsyning", gru009Vandforsyning)
      detailItemsList.append({
        type: "labelvalue",
        label: "Vand",
        value: vandforsyningsTekst
      })
    }

    if (bbrBygningResponse.byg031Afløbsforhold) {
      let byg031Afløbsforhold = bbrBygningResponse.byg031Afløbsforhold // Eks: 10
      let afloebsTekst = this.fetcher.findBbrKode("Afloebsforhold", byg031Afløbsforhold)
      detailItemsList.append({
        type: "labelvalue",
        label: "Afløb",
        value: afloebsTekst
      })
    } else if (grundeResponse) {
      let gru010Afløbsforhold = grundeResponse[0].gru010Afløbsforhold // Eks: 10
      let afloebsTekst = this.fetcher.findBbrKode("Afloebsforhold", gru010Afløbsforhold)
      detailItemsList.append({
        type: "labelvalue",
        label: "Afløb",
        value: afloebsTekst
      })
    }

    forsyningsItems.push(detailItemsList.asItem())

    return forsyningsItems
  }

  async getBygningsEnergiMaerkeSection(bbrBygningResponse) {
    let detailItemsList = new DetailItemsList({
      header: "Energimærke",
      name: "bygning_energi"
    })
    try {
      let grundeResponse = await this.fetcher.read("bbr", "bbr", "grund", { id: bbrBygningResponse.grund }, this.getLogger())
      if (grundeResponse && grundeResponse.length > 0) {
        let komnr = grundeResponse[0].bestemtFastEjendom.kommunekode.substring(1)
        let esrnr = grundeResponse[0].bestemtFastEjendom.ejendomsnummer
        let bygnr = bbrBygningResponse.byg007Bygningsnummer
        let url = "https://emoweb.dk/EMOData/EMOData.svc/SearchEnergyLabelBBR/" + komnr + "/" + esrnr + "/" + bygnr
        let result = await fetch2(url, { Authorization: { Basic: { username: "peter@septima.dk", password: "12345678" } } })
        if (result && result.ResponseStatus.Status === "RESULT_OK" && result.SearchResults && result.SearchResults.length > 0) {
          let id = result.SearchResults[0].EntityIdentifier
          let classification = result.SearchResults[0].EnergyLabelClassification
          detailItemsList.append({
            type: "labelvalue",
            label: "Klassifikation",
            value: classification
          })
          detailItemsList.append({
            type: 'link',
            link: `https://emoweb.dk/EMODigital/EMODigital.svc/PdfDocument/${id}`,
            linkTitle: "Rapport"
          })
          return [detailItemsList.asItem()]
        }
      }
      detailItemsList.append({
        type: "labelvalue",
        value: "Ikke energimærket"
      })
    } catch (e) {
      detailItemsList.append({
        type: "labelvalue",
        label: "Der skete en fejl ved hentning af energimærke"
      })
    }
    return [detailItemsList.asItem()]
  }

  async getBygningsArealerSection(bbrBygningResponse) {
    let detailItemsList = new DetailItemsList({
      header: "Arealer (m2)",
      name: "bygning_arealer"
    })

    let bebyggetAreal = bbrBygningResponse.byg041BebyggetAreal
    if (bebyggetAreal && bebyggetAreal != "") {
      detailItemsList.append({
        type: "labelvalue",
        label: "Bebygget areal",
        value: bbrBygningResponse.byg041BebyggetAreal,
        valueformat: "int"
      })
    }

    let etageAreal = bbrBygningResponse.byg038SamletBygningsareal
    if (etageAreal && etageAreal != "") {
      detailItemsList.append({
        type: "labelvalue",
        label: "Etageareal",
        value: bbrBygningResponse.byg038SamletBygningsareal,
        valueformat: "int"
      })
    }

    let erhvervsAreal = bbrBygningResponse.byg040BygningensSamledeErhvervsAreal
    if (erhvervsAreal && erhvervsAreal != "") {
      detailItemsList.append({
        type: "labelvalue",
        label: "Erhverv",
        value: erhvervsAreal,
        valueformat: "int"
      })
    }

    let samletBoligAreal = bbrBygningResponse.byg039BygningensSamledeBoligAreal
    if (samletBoligAreal && samletBoligAreal != "") {
      detailItemsList.append({
        type: "labelvalue",
        label: "Heraf bolig",
        value: samletBoligAreal,
        valueformat: "int"
      })
    }
    
    if (detailItemsList.items.length == 0)
      detailItemsList.append({
        type: "labelvalue",
        value: "Ingen arealer angivet"
      })

    return [detailItemsList.asItem()]
  }

  async getBygningsEjendomsSection(bbrBygningResponse) {
    let items = []
    if (bbrBygningResponse.bygningPåFremmedGrundList && bbrBygningResponse.bygningPåFremmedGrundList.length > 0) {
      if (bbrBygningResponse.bygningPåFremmedGrundList[0].bygningPåFremmedGrund == 7) {
        let bpgResult = await this.ejendomsSearcher.get(bbrBygningResponse.bygningPåFremmedGrundList[0].bygningPåFremmedGrund.bfeNummer, "bfg")
        if (bpgResult) {
          let detailItemsList = new DetailItemsList({
            header: "Indgår i bygning på fremmed grund",
            name: "bygning_bfg"
          })
          detailItemsList.append({
            type: "result",
            result: bpgResult
          })
          items.push(detailItemsList.asItem())
        }
      }
    }

    //grund
    let grundResponse = await this.fetcher.read("bbr", "bbr", "grund", { id: bbrBygningResponse.grund }, this.getLogger())
    let sfeBfeNummer = grundResponse[0].bestemtFastEjendom.samletFastEjendom
    let sfeResult = await this.ejendomsSearcher.get(sfeBfeNummer, "sfe")
    if (sfeResult) {
      let detailItemsList = new DetailItemsList({
        header: "Ligger på fast ejendom",
        name: "bygning_sfe"
      })
      detailItemsList.append({
        type: "result",
        result: sfeResult
      })
      items.push(detailItemsList.asItem())
    }
    return items
  }

  // eslint-disable-next-line no-unused-vars
  async getBoligerVirksomhederSection(bbrBygningResponse) {
    let items = []
    let detailItemsList = new DetailItemsList({
      header: "Boliger og virksomheder",
      name: "bygning_boliger_virksomheder"
    })

    detailItemsList.append({
      type: "labelvalue",
      label: "TBD",
      value: "TBD"
    })

    items.push(detailItemsList.asItem())
    return items
  }

  async getBbrKommuneLinks(bbrBygningResponse) {
    let detailItemsList = new DetailItemsList({
      header: "kommune.bbr.dk (Kræver login)",
      name: "bygning_bbr_links"
    })
    detailItemsList.append({
      type: "link",
      link: `https://kommune.bbr.dk/bob/BYG/${bbrBygningResponse.id_lokalId}`,
      linkTitle: "Se bygning"
    })
    detailItemsList.append({
      type: "link",
      link: `https://kommune.bbr.dk/bob/GRU/${bbrBygningResponse.grund}`,
      linkTitle: "Se grund"
    })

    return [detailItemsList.asItem()]
  }
  
  async getOmEnhedenSection(bbrdata) {
    let detailItemsList = new DetailItemsList({
      header: "Generelt om enheden",
      name: "bbrenhed_genrelt"
    })
    let anvendelseTekst = this.fetcher.findBbrKode("EnhAnvendelse", bbrdata.enh020EnhedensAnvendelse)
    detailItemsList.append({
      type: "labelvalue",
      label: "Anvendelse",
      value: anvendelseTekst
    })
    let boligtype = bbrdata.enh023Boligtype
    if (boligtype && boligtype != "") {
      let boligtypeTekst = this.fetcher.findBbrKode("Boligtype", bbrdata.enh023Boligtype)
      detailItemsList.append({
        type: "labelvalue",
        label: "Boligtype",
        value: boligtypeTekst
      })
    }
    let antalvaerelser = bbrdata.enh031AntalVærelser
    if (antalvaerelser && antalvaerelser != "") {

      detailItemsList.append({
        type: "labelvalue",
        label: "Antal værelser",
        value: bbrdata.enh031AntalVærelser,
        valueformat: "int"
      })
    }
    let udlejningsforhold = bbrdata.enh045Udlejningsforhold
    if (udlejningsforhold && udlejningsforhold != "") {
      let udlejningTekst = this.fetcher.findBbrKode("Udlejningsforhold", bbrdata.enh045Udlejningsforhold)
      detailItemsList.append({
        type: "labelvalue",
        label: "Udlejningsforhold",
        value: udlejningTekst
      })
    }

    return [detailItemsList.asItem()]
  }



  async getEnhedsArealerSection(bbrdata) {
    let detailItemsList = new DetailItemsList({
      header: "Arealer (m2)",
      name: "bbrenhed_arealer"
    })
    let kilde = bbrdata.enh030KildeTilEnhedensArealer
    if (kilde && kilde != "") {
      let kildeTekst = this.fetcher.findBbrKode("KildeTilOplysninger", bbrdata.enh030KildeTilEnhedensArealer)
      detailItemsList.append({
        type: "labelvalue",
        label: "Kilde til oplysninger",
        value: kildeTekst

      })
    }
    detailItemsList.append({
      type: "labelvalue",
      label: "Samlet areal",
      value: bbrdata.enh026EnhedensSamledeAreal,
      valueformat: "int"
    })
    let beboelse = bbrdata.enh027ArealTilBeboelse
    if (beboelse && beboelse != "") {
      detailItemsList.append({
        type: "labelvalue",
        label: "Beboelse",
        value: bbrdata.enh027ArealTilBeboelse,
        valueformat: "int"
      })
    }
    let andet = bbrdata.enh039AndetAreal
    if (andet && andet != "") {
      detailItemsList.append({
        type: "labelvalue",
        label: "Andet",
        value: bbrdata.enh039AndetAreal,
        valueformat: "int"
      })
    }

    return [detailItemsList.asItem()]
  }
  async getEnhedsBadSection(bbrdata) {
    let detailItemsList = new DetailItemsList({
      header: "Bad",
      name: "bbrenhed_bad"
    })
    let badeforhold = bbrdata.enh033Badeforhold
    if (badeforhold && badeforhold != "") {
      let badeforholdTekst = this.fetcher.findBbrKode("Badeforhold", bbrdata.enh033Badeforhold)
      detailItemsList.append({
        type: "labelvalue",
        label: "Badeforhold",
        value: badeforholdTekst

      })
    }
    let antalbad = bbrdata.enh066AntalBadeværelser
    if (antalbad && antalbad != "") {
      detailItemsList.append({
        type: "labelvalue",
        label: "Antal badeværelser",
        value: bbrdata.enh066AntalBadeværelser,
        valueformat: "int"

      })
    }

    return [detailItemsList.asItem()]
  }
  async getEnhedsToiletSection(bbrdata) {
    let detailItemsList = new DetailItemsList({
      header: "Toilet",
      name: "bbrenhed_toilet"
    })
    let toiletforhold = bbrdata.enh032Toiletforhold
    if (toiletforhold && toiletforhold != "") {
      let toiletforholdTekst = this.fetcher.findBbrKode("Toiletforhold", bbrdata.enh032Toiletforhold)
      detailItemsList.append({
        type: "labelvalue",
        label: "Toiletforhold",
        value: toiletforholdTekst

      })
    }
    let antalvandskyllendetoiletter = bbrdata.enh065AntalVandskylledeToiletter
    if (antalvandskyllendetoiletter && antalvandskyllendetoiletter != "") {
      detailItemsList.append({
        type: "labelvalue",
        label: "Antal vandskyllende toiletter",
        value: antalvandskyllendetoiletter,
        valueformat: "int"

      })
    }

    return [detailItemsList.asItem()]
  }
  async getEnhedsKoekkenSection(bbrdata) {
    let detailItemsList = new DetailItemsList({
      header: "Køkken",
      name: "bbrenhed_koekken"
    })
    let koekkenforhold = bbrdata.enh034Køkkenforhold
    if (koekkenforhold && koekkenforhold != "") {
      let koekkenforholdTekst = this.fetcher.findBbrKode("Koekkenforhold", bbrdata.enh034Køkkenforhold)
      detailItemsList.append({
        type: "labelvalue",
        label: "Køkkenforhold",
        value: koekkenforholdTekst

      })
    }
    let antalvandskyllendetoiletter = bbrdata.enh065AntalVandskylledeToiletter
    if (antalvandskyllendetoiletter && antalvandskyllendetoiletter != "") {
      detailItemsList.append({
        type: "labelvalue",
        label: "Antal vandskyllende toiletter",
        value: antalvandskyllendetoiletter,
        valueformat: "int"

      })
    }

    return [detailItemsList.asItem()]
  }


  async getBbrObjectsForHusnummer(id) {
    let objects = []
    let dawaSearcher = new DawaSearcher({})
    let enhedsAdresser = await dawaSearcher.getEnhedsAdresserForAdresse(id)
    let adresseIder = enhedsAdresser.features.map(f => f.properties.id)
    for (let adresseId of adresseIder)
      objects.push(...await this.fetcher.read("bbr", "bbr", "enhed", { AdresseIdentificerer: adresseId }, this.getLogger()))
    return objects
  }
}