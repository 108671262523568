/**
 * @module
 */
import Searcher from '../Searcher.js'
import ResultType from "../../ResultType.js"
import Projection from 'ol/proj/Projection.js'
import { addProjection } from 'ol/proj.js'
import { WFS} from 'ol/format.js'
import GML3 from 'ol/format/GML3.js'
import GeoJSON from 'ol/format/GeoJSON.js'
import { intersects as intersectsFilter, equalTo as equalToFilter, and as andFilter, or as orFilter } from 'ol/format/filter.js'
import * as reproject from "../../util/reproject.js"
import DataApiFetcher from "../data-api/Fetcher.js"
import icons from "../../resources/icons.js"
import GSearchFetcher from "./GSearchFetcher.js"

/**
 *
 * Searches gst geoservice (https://docs.dataforsyningen.dk/#gsearch-dokumentation)
 * @extends module:js/searchers/Searcher
 * @example <caption>YAML Declaration:</caption>
 _type: Septima.Search.GeoSearch
   _options:
     targets:
     - matrikelnumre
     - sogne
     authParamsKortforsyningen:
     token: xxxx
     authParamsDatafordeleren:
     username: xxxx
     password: xxxx
     kommunekode: '101 157'
 * @example <caption> JS options:</caption>
 geoSearchOptions = {
    "targets": ['matrikelnumre', 'sogne'],
    "authParamsKortforsyningen": {
      "token": 'xxxxx'
    },
    "authParamsDatafordeleren": {
      "username": 'xxxxx',
      "password": 'xxxx'
    },
    kommunekode: '157 101'
  };
 * @example <caption>js client:</caption>
 * <!-- Include septimaSearch -->
 * <script type="text/javascript" src="http://search.cdn.septima.dk/{version}/septimasearch.min.js"/>
 * controller.addSearcher(new Septima.Search.GeoSearch(geoSearchOptions))
 *
 * @example <caption>ES6:</caption>
 * import GeoSearch from './searchers/GeoSearch.js'
 * controller.addSearcher(new GeoSearch(geoSearchOptions))
 * @api
 */
export default class GeoSearch extends Searcher {
  /**
   * @param {Object} options GeoSearch expects these properties:
   * @param {String} [options.kommunekode=*] "*" Search all municipalities (Default)<br/> Search specific municipalities eg. "101" or "101 256"
   * @param options.targets {string[]} List of targets to search<br/> The full list is ['kommuner', 'matrikelnumre', 'opstillingskredse', 'politikredse', 'postdistrikter', 'regioner', 'retskredse', 'sogne', 'matrikelnumre_udgaaet']<br/> ['*'] is short hand for ['kommuner', 'matrikelnumre', 'opstillingskredse', 'politikredse', 'postdistrikter', 'regioner', 'retskredse', 'sogne']
   * @param options.authParamsKortforsyningen {Object} Either {token: 'ttttt'}, where t is a ticket issued by kortforsyningen or {login: 'llll', password: 'pppp'}
   * @param options.authParamsDatafordeleren {Object} Tjenestebruger til Datafordeleren {username: 'uuuu', password: 'pppp'}
   */
  constructor(options={}) {

    super(Object.assign({
      usesGeoFunctions: true,
      defaultCrs: "25832",
      iconURI:icons.result.defaultIcon
    },
    options))

    this.serviceUrl =  'https://kortforsyningen.kms.dk'

    // https://services.datafordeler.dk/DAGIM/DAGI_10MULTIGEOM_GMLSFP/1.0.0/WFS?service=WFS&version=2.0.0&request=GetCapabilities&username=GVMHDDIEUG&password=dCu!FNMpnbf7DGm
    // https://services.datafordeler.dk/MATRIKEL/MatrikelGaeldendeOgForeloebigWFS/1.0.0/WFS?service=WFS&version=2.0.0&request=GetCapabilities&username=GVMHDDIEUG&password=dCu!FNMpnbf7DGm
    //https://datafordeler.dk/dataoversigt/matriklen-mat/matriklen-med-historik-wfs/
    this.geoTypes = [
      {gresource: 'matrikel', resource: 'matrikelnumre', returnType: "matrikelnummer", singular: 'Matrikelnummer', plural: 'Matrikelnumre', wfstypename: 'Jordstykke_Gaeldende', icon: icons.searchers.geoSearch.matrikelnr},
      {gresource: 'kommune', resource: 'kommuner', returnType: "kommune", singular: 'Kommune', plural: 'Kommuner', wfstypename: 'Kommuneinddeling'},
      {gresource: 'opstillingskreds', resource: 'opstillingskredse', returnType: "opstillingskreds", singular: 'Opstillingskreds', plural: 'Opstillingskredse', wfstypename: 'Opstillingskreds'},
      {gresource: 'politikreds', resource: 'politikredse', returnType: "politikreds", singular: 'Politikreds', plural: 'Politikredse', wfstypename: 'Politikreds'},
      {gresource: 'postnummer', resource: 'postdistrikter', returnType: "postdistrikt", singular: 'Postdistrikt', plural: 'Postdistrikter', wfstypename: 'Postnummerinddeling'},
      {gresource: 'region', resource: 'regioner', returnType: "region", singular: 'Region', plural: 'Regioner', wfstypename: 'Regionsinddeling'},
      {gresource: 'matrikel_udgaaet', resource: 'matrikelnumre_udgaaet', returnType: "matrikelnummer_udgaaet", singular: 'Udgået matrikelnummer', plural: 'Udgåede matrikelnumre', wfstypename: 'Jordstykke', icon: icons.searchers.geoSearch.matrikelnr},
      {gresource: 'retskreds', resource: 'retskredse', returnType: "retskreds", singular: 'Retskreds', plural: 'Retskredse', wfstypename: 'Retskreds'},
      {gresource: 'sogn', resource: 'sogne', returnType: "sogn", singular: 'Sogn', plural: 'Sogne', wfstypename: 'Sogneinddeling'}
    ]

    // Comments
    // - "returnType" is the type, that GeoSearch returns even though the service is called with "target". The value of "returnType" is different from "target/resource"
    // - "resource", "typeId" and "target" are all the same

    if (!options.source)
      options.source = "Kortforsyningen"

    this.source = options.source

    this.authParamsKortforsyningen = options.authParamsKortforsyningen || options.authParams || {
      token: '22e4c7f57b6172b780abf7a447ebe7bd'
    }
    
    this.authParamsDatafordeler = options.authParamsDatafordeler || {
      username: 'GVMHDDIEUG',
      password: 'dCu!FNMpnbf7DGm'
    }

    this.myTypes = []
    let gSearchTypes = []
    if (!options.targets)
      options.targets = []

    this.targetResources = []
    for (let type of this.geoTypes) {
      let targetFound = false
      for (let target of options.targets)
        if (type.resource === target || (target === '*' &&  type.resource!= 'matrikelnumre_udgaaet')) {
          targetFound = true
          this.myTypes.push(type)
          this.targetResources.push(type.resource)
          gSearchTypes.push(type.gresource)
          let resultType = new ResultType({
            id: type.resource,
            singular: type.singular,
            plural: type.plural,
            iconURI: type.icon ? type.icon : icons.multipolygon
          })
          type.resultType = resultType
          this.registerType(this.source, resultType)
        }

      if (!targetFound) {
        let resultType = new ResultType({
          id: type.resource,
          singular: type.singular,
          plural: type.plural,
          queryBehaviour: "none",
          iconURI: type.icon ? type.icon : icons.multipolygon
        })
        type.resultType = resultType
        this.registerType(this.source, resultType)
      }
    }
    this.resourcesParam = this.targetResources.join(',')

    this.iconURI = icons.searchers.geoSearch.result

    this.keyvalues = {}

    const projection = new Projection({
      code: 'EPSG:25832',
      extent: [120000, 5661139.2, 958860.8, 6500000],
      units: 'm'
    })
    addProjection(projection)

    reproject.registerCrs("EPSG:25832", "+proj=utm +zone=32 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs")

    this.gSearchFetcher = new GSearchFetcher({kommunekode: options.kommunekode, authParamsDataforsyningen: this.authParamsKortforsyningen, convert2Geo: true, defaultTypes: gSearchTypes})

  }

  async fetchData(query, caller) {
    switch (query.type) {
      case 'collapse': {
        caller.fetchSuccess(this.createQueryResultFromResources())
        break
      }
      case 'cut':
      case 'no-cut':
      case 'list': {
        try {
          let data = await this.callGSearch(query)
          data.query = query
          let queryResult = this.parseResult(data.data, query)
          caller.fetchSuccess(queryResult)
        } catch (error) {
          caller.fetchError(this, error)
        }
      }
    }
  }

  createQueryResultFromResources() {
    const queryResult = this.createQueryResult()
    for (let type of this.myTypes) {
      let plural = this.getType(this.source, type.resource).plural
      queryResult.addNewQuery(this.source, type.resource, plural, null, "", null, null)
    }
    return queryResult
  }

  callGSearch(query) {
    let gSearchType = null
    const type = this.getTypeFromQuery(query)
    if (type != null) {
      gSearchType = type.gresource
    }
    return this.gSearchFetcher.fetchFromGSearch(query.queryString, query.limit + 1, gSearchType)
  }
  
  getTypeFromQuery(query) {
    if (query.hasTarget && this.hasType(query.target.type))
      for (let type of this.myTypes)
        if (query.target.type.toLowerCase() === type.resource.toLowerCase())
          return type
    return null
  }

  getTypeFromTypeId(typeId) {
    if (typeId === 'lodflade')
      return {wfstypename : 'Lodflade'}
    for (let type of this.geoTypes)
      if (typeId === type.resource)
        return type
    return null
  }

  parseResult(data, query) {
    const queryResult = this.createQueryResult()
    const resultTypes = []
    if (data !== null) {
      let setDescription = !query.hasTarget || this.targetResources.length == 1
      this.fixHits(data, setDescription)
      let nextTypeIndex = 0
      while (nextTypeIndex > -1 && nextTypeIndex < data.length) {
        resultTypes.push(data[nextTypeIndex].type)
        nextTypeIndex = this.procesTypeFromIndex(data, nextTypeIndex, query, queryResult)
      }
    }
    if (!query.hasTarget && !query.isBlank)
      for (let thisType of this.myTypes)
        if (thisType.plural.toLowerCase().indexOf(query.queryString.toLowerCase()) === 0) {
          let foundInResultTypes = false
          for (let thisResultType of resultTypes)
            if ( thisType.returnType === thisResultType)
              foundInResultTypes = true
          if (!foundInResultTypes) {
            let plural = this.getType(this.source, thisType.resource).plural
            queryResult.addNewQuery(this.source, thisType.resource, plural, null, "", null, null)
          }
        }
    return queryResult
  }

  fixHits(hits, setDescription) {
    for (let hit of hits)
      switch (hit.type) {
        case "matrikelnummer":
          hit.presentationString = hit.matrnr + ", " + hit.elavsnavn
          break
        case "matrikelnummer_udgaaet":
          hit.presentationString = hit.matrnr + ", " + hit.elavsnavn + " (Udgået)"
          break
        case "opstillingskreds":
          if (setDescription)
            hit.descriptionString = "Opstillingskreds i " + hit.storkredsnavn + "s storkreds"
          else
            hit.descriptionString = hit.storkredsnavn + "s storkreds"
          break
        case "retskreds":
          if (setDescription)
            hit.descriptionString = "Retskreds"
          break
        case "postdistrikt":
          hit.presentationString = hit.id + " " + hit.name
          if (setDescription)
            hit.descriptionString = "Postdistrikt"
          break
      }
  }

  procesTypeFromIndex(data, fromIndex, query, queryResult) {

    if (fromIndex < 0 || fromIndex >= data.length)
      return -1


    let index = fromIndex
    const returnType = data[index].type
    const typeHits = []

    while (index < data.length && data[index].type === returnType) {
      typeHits.push(data[index])
      index++
    }

    const type = this.getTypeFromReturnType(returnType)
    let plural = this.getType(this.source, type.resource).plural

    const count = typeHits.length
    let hitsShown = (count === 1) ? 1 : (query.type === 'no-cut' && count > query.limit) ? 0 : Math.min(count, query.limit)

    for (let thisHit of typeHits.slice(0, hitsShown))
      this.addResultFromHit(queryResult, thisHit, type.resource)
    if ( count > hitsShown && ["no-cut", "cut"].indexOf(query.type) !== -1 ) {
      if (hitsShown > 0)
        queryResult.addNewQuery(this.source, type.resource, "Flere " + plural, null, query.queryString, null, null)
      else
        queryResult.addNewQuery(this.source, type.resource, plural, null, query.queryString, null, null)
    }

    return index
  }

  getTypeFromReturnType(returnType) {
    for (let type of this.geoTypes)
      if (returnType === type.returnType)
        return type
    return null
  }

  addResultFromHit(queryResult, thisHit, typeId) {
    let resultGeometry = thisHit.geometry

    let result = queryResult.addResult(this.source, typeId, thisHit.presentationString, thisHit.descriptionString ? thisHit.descriptionString : null, resultGeometry, thisHit)

    switch (thisHit.type) {
      case "matrikelnummer":
      case "matrikelnummer_udgaaet":
        result.isComplete = false
        result.id = thisHit.id
        break
      case "politikreds":
        result.id = thisHit.myndighedsKode
        break
      case "retskreds":
        result.id = thisHit.myndighedsKode
        break
      case "sogn":
      case "region":
      case "kommune":
      case "opstillingskreds":
      case "postdistrikt":
        result.id = thisHit.id
        break
    }
  }
  
  async completeResult(result) {
    if (result.isComplete) {
      return result
    } else {
      result.isComplete = true

      if (result.data.type === "matrikelnummer") {
        let idents = result.id.split('-')
        let ejdInfo = await this.getEjdInfo(idents[0], idents[1])
        if (ejdInfo.esr && ejdInfo.sfe) {
          result.data.esrejdnr = ejdInfo.esr
          result.data.sfeejdnr = ejdInfo.sfe
        }

        return result
      } else if (result.data.type === "matrikelnummer_udgaaet") {
        let result2 = await this.get(result.id, "matrikelnumre_udgaaet")
        return result2
      } else {
        return result
      }
    }
  }

  async sq(query) {
    const queryResult = this.createQueryResult()
    let geometry
    if (query.geometry) {
      geometry = new GeoJSON().readGeometry(query.geometry)
    }
    if (geometry) {
      if (query.target) {
        // Query a specific type
        const geojson = await this.queryWfsByGeometry(geometry, query.target.type)
        this.addFeatureCollectionToQueryResult(queryResult, query.target.type, geojson)
      } else {
        // Query all selected types
        const types = this.geoTypes.filter((t) => this.resourcesParam.indexOf(t.resource) !== -1)
        await Promise.all(types.map(async (type) => {
          const geojson = await this.queryWfsByGeometry(geometry, type.resource)
          this.addFeatureCollectionToQueryResult(queryResult, type.resource, geojson)
        }))
      }
    }
    return queryResult
  }

  addFeatureCollectionToQueryResult(queryResult, typeId, featureCollection) {
    let type = this.getTypeFromTypeId(typeId)

    featureCollection.features.forEach(feature => {
      const data = this.featureToData(typeId, feature)
      if (data) {
        let geometry = feature.geometry
        if (geometry)
          geometry.crs = {
            "type": "name",
            "properties": {
              "name": "epsg:25832"
            }
          }
        let result = queryResult.addResult(this.source, typeId, data.presentationString, null, geometry, data)
        result.id = data.id
        result.distance = 0
        type.resultType.cache.setResult(result)
      }
    })
  }

  featureToData(typeId, feature) {
    const p = feature.properties
    const wkt = this.translateGeoJsonObjectToWkt(feature.geometry)
    if (typeId === 'matrikelnumre') {
      let interiorPoint = this.wktParser.getInteriorPoint(feature.geometry)
      return {
        "centroid_x": parseInt(interiorPoint.coordinates[0]),
        "centroid_y":  parseInt(interiorPoint.coordinates[1]),
        "lokalId": p['id.lokalId'],
        "type": "matrikelnummer",
        "id": p.ejerlavskode + '-' + p.matrikelnummer,
        "elavsnavn": this.keyvalues.ejerlav[p.ejerlavskode],
        "elavskode": p.ejerlavskode-0,
        "matrnr": p.matrikelnummer,
        "presentationString": p.matrikelnummer + ' ' + this.keyvalues.ejerlav[p.ejerlavskode],
        "geometryWkt": wkt,
        "geometryWkt_detail": wkt,
        // "esrejdnr": p.esr_Ejendomsnummer,  // ESR Ejendomsnummer not awailable from the service on Datafordeleren
        "sfeejdnr": p.samletFastEjendomLokalId-0
      }
    } else if (typeId === 'kommuner') {
      const name = p.navn + (p.kommunekode === '0101' ? 's kommune' : ' kommune')
      return {
        "lokalId": p['id.lokalId'],
        "type": "kommune",
        "id": p.kommunekode,
        "name": p.navn,
        "presentationString": name + ' (' + p.kommunekode + ')',
        "geometryWkt": wkt,
        "geometryWkt_detail": wkt
      }
    } else if (typeId === 'regioner') {
      return {
        "lokalId": p['id.lokalId'],
        "type": "region",
        "id": p.regionskode,
        "name": p.navn,
        "presentationString": p.navn + ' (' + p.regionskode + ')',
        "geometryWkt": wkt,
        "geometryWkt_detail": wkt
      }
    } else if (typeId === 'opstillingskredse') {
      return {
        "lokalId": p['id.lokalId'],
        "type": "opstillingskreds",
        "id": p.opstillingskredsnummer,
        "name": p.navn,
        "presentationString": p.navn + "kredsen",
        "geometryWkt": wkt,
        "geometryWkt_detail": wkt,
        "valgkredsNr": p.valgkredsnummer,
        "storkredsNr": p.storkredsnummer,
        // "storkredsNavn": p.Storkredsnummer_tekst,
        // "landsdelsNr": p.Landsdelsnummer,
        // "landsdelsNavn": p.Landsdelsnummer_tekst,
        // "descriptionString": p.Storkredsnummer_tekst + " storkreds"
      }
    } else if (typeId === 'politikredse') {
      return {
        "lokalId": p['id.lokalId'],
        "type": "politikreds",
        "id": p.myndighedskode,
        "name": p.navn,
        "presentationString": p.navn + "kreds",
        "geometryWkt": wkt,
        "geometryWkt_detail": wkt,
        "myndighedsKode": p.myndighedskode
      }
    } else if (typeId === 'postdistrikter') {
      return {
        "lokalId": p['id.lokalId'],
        "type": "postdistrikt",
        "id": p.postnummer,
        "name": p.navn,
        "presentationString": p.postnummer + ' ' + p.navn,
        "geometryWkt": wkt,
        "geometryWkt_detail": wkt
      }
    } else if (typeId === 'retskredse') {
      return {
        "lokalId": p['id.lokalId'],
        "type": "retskreds",
        "id": p.myndighedskode,
        "name": p.navn,
        "presentationString": p.navn,
        "geometryWkt": wkt,
        "geometryWkt_detail": wkt,
        "myndighedsKode": p.myndighedskode
      }
    } else if (typeId === 'sogne') {
      return {
        "lokalId": p['id.lokalId'],
        "type": "sogn",
        "id": p.sognekode,
        "name": p.navn,
        "presentationString": p.navn,
        "geometryWkt": wkt,
        "geometryWkt_detail": wkt
      }
    }
    return p
  }

  async get(id, typeId) {
    const queryResult = this.createQueryResult()
    let type = this.getTypeFromTypeId(typeId)
    let cachedResult = type?.resultType?.cache.get(id)
    if (cachedResult) {
      return cachedResult
    } else {
      if (typeId == 'matrikelnumre_udgaaet') {
        let result = await this.createUdgaaetMatrikelResult(id, typeId)
        type.resultType.cache.setResult(result)
        return result
      } else {
        let filter = this.createFilter(id, typeId)
        let geojson = await this.queryWfsWithFilter(filter, typeId)
        if (geojson.features.length > 0) {
          let feature = geojson.features[0]
          const data = this.featureToData(typeId, feature)
          if (data) {
            let result = queryResult.addResult(this.source, typeId, data.presentationString, null, feature.geometry, data)
            result.id = id
            type.resultType.cache.setResult(result)
            return result
          }
        }
      }
    }
    return
  }
  
  async gets(ids, typeId) {
    let results = []
    if (typeId == 'matrikelnumre_udgaaet') {
      for (let id of ids)
        results.push( await this.createUdgaaetMatrikelResult(id, typeId) )
      return results
    } else {
      const queryResult = this.createQueryResult()
      let filter = this.createMultiFilter(ids, typeId)
      let geojson = await this.queryWfsWithFilter(filter, typeId)
      for (let feature of geojson.features) {
        const data = this.featureToData(typeId, feature)
        if (data) {
          let result = queryResult.addResult(this.source, typeId, data.presentationString, null, feature.geometry, data)
          result.id = data.elavskode + "-" + data.matrnr
          results.push(result)
        }
      }
      return results
    }
  }
  
  async createUdgaaetMatrikelResult(id, typeId) {
    //Get jordstykke fra historisk
    let filter = this.createFilter(id, typeId)
    let jordstykkeGeoJson = await this.queryWfsWithFilter(filter, typeId)
    if (jordstykkeGeoJson.features.length > 0) {
      let jordStykkeFeature = jordstykkeGeoJson.features[0] //TBD: sort
      let jordstykkeData = this.featureToData(typeId, jordStykkeFeature)
      let jordstykkeLokalId = jordstykkeData.id.Identifikation.lokalId
      //get lod fra historisk
      let filter = andFilter(
        equalToFilter('jordstykkeLokalId', jordstykkeLokalId),
        equalToFilter('status', 'Historisk')
      )
      let lodGeoJson = await this.queryWfsWithFilter(filter, "lodflade")
      if (lodGeoJson.features.length > 0) {
        let lodFeature = lodGeoJson.features[0] //TBD: sort
        let lodData = this.featureToData("lodflade", lodFeature)
        let finalData = Object.assign({}, jordstykkeData, lodData)
        let geometry = lodFeature.geometry
        if (geometry)
          geometry.crs = {
            "type": "name",
            "properties": {
              "name": "epsg:25832"
            }
          }
        const queryResult = this.createQueryResult()
        let title = jordstykkeData.matrikelnummer + ' ' + this.keyvalues.ejerlav[jordstykkeData.ejerlavLokalid] + " (Udgået)"
        let result = queryResult.addResult(this.source, typeId, title, null, geometry, finalData)
        result.id = id
        return result
      }
    }

    //Merge dem som resultat
  }
  
  async getEjdInfo(ejkode, matnr) {
    try {
      //kald daf(mat/mat//samletfastejendom med ejkode, matnr -> returnerer bfe
      let params = Object.keys(this.authParamsDatafordeler).map(key => key + '=' + this.authParamsDatafordeler[key]).join('&')
      let url = `https://services.datafordeler.dk/Matriklen2/Matrikel/1.0.0/rest/SamletFastEjendom?${params}&Ejerlavskode=${ejkode}&Matrikelnr=${matnr}`
      let dafData = await this.fetch(url)
      if (dafData && dafData.features.length > 0) {
        let bfeNummer = dafData.features[0].properties.BFEnummer

        let dataApiFetcher = new DataApiFetcher()
        let esrEjendomme = await dataApiFetcher.get("bestemtfastejendom", {bfenummer: `eq.${bfeNummer}`, select: "esrejendom(*)"})
        if (esrEjendomme && esrEjendomme[0].esrejendom && esrEjendomme[0].esrejendom.length > 0) {
          let esrnr = esrEjendomme[0].esrejendom[0].ejendomsnummer
          return {esr: esrnr, sfe: bfeNummer}
        }
      }
    } catch(e) {
      return {esr: null, sfe: null}
    }
    return {esr: null, sfe: null}
  }
  
  //WFS stuff
  
  createFilter(id, typeId) {
    let filter
    if (typeId === 'matrikelnumre') {
      const idSplit = id.split('-')
      filter = andFilter(
        equalToFilter('ejerlavskode', idSplit[0]),
        equalToFilter('matrikelnummer', idSplit[1])
      )
    } else if (typeId === 'matrikelnumre_udgaaet') {
      const idSplit = id.split('-')
      filter = andFilter(
        equalToFilter('ejerlavLokalid', idSplit[0]),
        equalToFilter('matrikelnummer', idSplit[1]),
        equalToFilter('status', 'Historisk')
      )
    } else {
      const ids = {
        kommuner: 'kommunekode',
        regioner: 'regionskode',
        sogne: 'sognekode',
        opstillingskredse: 'opstillingskredsnummer',
        politikredse: 'myndighedskode',
        postdistrikter: 'postnummer',
        retskredse: 'myndighedskode'
      }
      filter = equalToFilter(ids[typeId], id)
    }
    return filter
  }

  createMultiFilter(ids, typeId) {
    let filters = []
    if (typeId === 'matrikelnumre') {
      for (let id of ids) {
        const idSplit = id.split('-')
        let filter = andFilter(
          equalToFilter('ejerlavskode', idSplit[0]),
          equalToFilter('matrikelnummer', idSplit[1])
        )
        filters.push(filter)
      }
    } else if (typeId === 'matrikelnumre_udgaaet') {
      for (let id of ids) {
        const idSplit = id.split('-')
        let filter = andFilter(
          equalToFilter('ejerlavLokalid', idSplit[0]),
          equalToFilter('matrikelnummer', idSplit[1]),
          equalToFilter('status', 'Historisk')
        )
        filters.push(filter)
      }
    } else {
      const idFields = {
        kommuner: 'kommunekode',
        regioner: 'regionskode',
        sogne: 'sognekode',
        opstillingskredse: 'opstillingskredsnummer',
        politikredse: 'myndighedskode',
        postdistrikter: 'postnummer',
        retskredse: 'myndighedskode'
      }
      let propertyName = idFields[typeId]
      for (let id of ids) {
        filters.push(equalToFilter(propertyName, id))
      }
    }
    if (filters.length > 1)
      return orFilter(...filters)
    else
      return filters[0]
  }


  async queryWfsByGeometry(geometry, typeId) {
    const filter = intersectsFilter('geometri', geometry)
    const geojson = await this.queryWfsWithFilter(filter, typeId)
    return geojson
  }

  async queryWfsWithFilter(filter, typeId) {
    let { wfstypename } = this.getTypeFromTypeId(typeId)
    const params = Object.keys(this.authParamsDatafordeler).map(key => key + '=' + this.authParamsDatafordeler[key]).join('&')
    
    if ((typeId === 'matrikelnumre' || typeId == 'matrikelnumre_udgaaet') && !this.keyvalues.ejerlav) {
      if (this.ejerlavsPromise)
        this.keyvalues.ejerlav = await this.ejerlavsPromise
      else {
        this.ejerlavsPromise = this.fetch('https://common.cdn.septima.dk/latest/data/kodeliste_ejerlavsnavn.json')
        this.keyvalues.ejerlav = await this.ejerlavsPromise
      }
      //this.keyvalues.ejerlav = await this.fetch('https://common.cdn.septima.dk/latest/data/kodeliste_ejerlavsnavn.json')
    }

    let featureNS = 'http://data.gov.dk/schemas/dagi/2/gml3sfp'
    if (typeId === 'matrikelnumre') {
      featureNS = 'http://data.gov.dk/schemas/matrikel/1'
    } else if (typeId === 'matrikelnumre_udgaaet' || typeId === 'lodflade') {
      featureNS = 'http://data.gov.dk/schemas/matrikel/1/gml3'
    }
    
    let featurePrefix = 'dagi'
    if (typeId === 'matrikelnumre' || typeId === 'matrikelnumre_udgaaet' || typeId === 'lodflade') {
      featurePrefix = 'mat'
    }
    //const featureNS = ((typeId === 'matrikelnumre' || typeId === 'matrikelnumre_udgaaet') ? 'http://data.gov.dk/schemas/matrikel/1' : 'http://data.gov.dk/schemas/dagi/2/gml3sfp')
    //const featurePrefix = ((typeId === 'matrikelnumre' || typeId === 'matrikelnumre_udgaaet') ? 'mat' : 'dagi')

    // let path = (typeId === 'matrikelnumre' ? 'MATRIKEL/Matrikel_HIST_GML3_1_0' : 'DAGIM/DAGI_10MULTIGEOM_GMLSFP')
    let path = 'DAGIM/DAGI_250MULTIGEOM_GMLSFP'
    if (typeId === 'matrikelnumre') {
      //path = 'MATRIKEL/MatrikelGaeldendeOgForeloebigWFS'
      path = 'MATRIKLEN2/MatGaeldendeOgForeloebigWFS'
    } else if (typeId === 'matrikelnumre_udgaaet' || typeId === 'lodflade') {
      //https://services.datafordeler.dk/MATRIKEL/Matrikel_HIST_GML3_1_0/1.0.0/WFS?username=GVMHDDIEUG&password=dCu!FNMpnbf7DGm&service=WFS&request=GetCapabilities
      //https://services.datafordeler.dk/MATRIKEL/Matrikel_HIST_GML3_1_0/1.0.0/WFS?username=GVMHDDIEUG&password=dCu!FNMpnbf7DGm&service=WFS&request=DescribeFeatureType&typeNames=mat:Jordstykke&version=2.0.0&NAMESPACES=xmlns(mat,http://data.gov.dk/schemas/matrikel/1/gml3)      path = 'MATRIKEL/Matrikel_HIST_GML3_1_0'
      path = 'MATRIKEL/Matrikel_HIST_GML3_1_0'
    } else if (typeId === 'postdistrikter') {
      path = 'DAGIM/DAGI_10MULTIGEOM_GMLSFP'
    }

    const featureRequest = new WFS().writeGetFeature({
      srsName: 'EPSG:25832',
      featureNS,
      featurePrefix,
      featureTypes: [wfstypename],
      outputFormat: 'xml',
      filter
    })

    let body = new XMLSerializer().serializeToString(featureRequest)
    //body = body.replace(/Intersects/g, 'Touches')
    // const url = `https://dfd-qgis-wfs-proxy.septima.dk/${path}/1.0.0/Wfs?${params}&SERVICE=WFS&REQUEST=GetFeature&VERSION=2.0.0&NAMESPACES=xmlns(${featurePrefix},${featureNS})`
    const url = `https://services.datafordeler.dk/${path}/1.0.0/Wfs?${params}&SERVICE=WFS&REQUEST=GetFeature&VERSION=2.0.0&NAMESPACES=xmlns(${featurePrefix},${featureNS})`
    let xml = await this.fetch(url, {
      method: 'post',
      expects: 'xml',
      body: body
    })
    xml = xml.replace('http://www.opengis.net/gml/3.2', 'http://www.opengis.net/gml')
    xml = xml.replace('xmlns:wfs="http://www.opengis.net/wfs/2.0"', 'xmlns:wfs="http://www.opengis.net/gml"')
    xml = xml.split('wfs:member').join('wfs:featureMember')
    xml = xml.replace(/(\d),0.0/g, '$1') // Remove z-values from coordinates
    xml = xml.replace(/(\d),(\d)/g, '$1 $2') // Remove , from coordinates
    xml = xml.replace(/<gml:coordinates/g, '<gml:posList srsDimension="2"')
    xml = xml.replace(/<\/gml:coordinates/g, '</gml:posList')

    const format = new GML3()
    const features = format.readFeatures(xml)
    const text = new GeoJSON().writeFeatures(features)
    const geojson = JSON.parse(text)
    geojson.features.forEach(f => this.removeZ(f.geometry?.coordinates))
    return geojson
  }
  
  removeZ(coordinates) {
    if (coordinates ) {
      if (coordinates.length > 0 && Array.isArray(coordinates[0])) {
        for (let i = 0; i < coordinates.length; i++) {
          this.removeZ(coordinates[i])
        }
      } else {
        coordinates.length = 2
      }
    }
  }

}