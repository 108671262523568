
import Searcher from "../Searcher.js"
import ResultType from "../../ResultType.js"
import icons from "../../resources/icons.js"
import * as reproject from "../../util/reproject.js"
import {getWKTParser} from "../../util/getWKTParser.js"
export default class CvrSearcher extends Searcher {
  constructor(options={}) {
    if (!options.fetcher)
      throw new Error("CvrSearcher expects options.fetcher")
    if (options.logger)
      options.logger.warn("redata,CvrSearcher is deprecated - Use Septima.Search.DataApi.")
    else
      try{
        // eslint-disable-next-line no-console
        console.warn("redata,CvrSearcher is deprecated - Use Septima.Search.DataApi.")
        // eslint-disable-next-line no-empty
      } catch(e) {}
    
    let defaultOptions = {
      usesGeoFunctions: true,
      defaultCrs: "25832",
      kommunekode: "*",
      goal: "*"
    }
    let finalOptions = Object.assign(defaultOptions, options) 
    super(finalOptions)
    
    this.kommunekode = "" + finalOptions.kommunekode
    this.goal = finalOptions.goal
    this.source = "cvr"
    
    this.fetcher = options.fetcher
    
    this.cvrNrType = new ResultType({
      id: "virksomhed",
      geometrySupport: "sq",
      queryBehaviour: this.goal == "produktionsenhed" ? "none" : "search",
      singular: "Virksomhed",
      plural: "Virksomheder",
      iconURI: icons.searchers.cvr,
      itemFunction: this.addCvrnrItemToQueryResult.bind(this)
    })
    
    this.pNrType = new ResultType({
      id: "produktionsenhed",
      geometrySupport: "sq",
      queryBehaviour: this.goal == "virksomhed" ? "none": "search",
      singular: "Produktionsenhed",
      plural: "Produktionsenheder",
      iconURI: icons.searchers.cvr,
      itemFunction: this.addPnrItemToQueryResult.bind(this)
    })
    
    this.registerType(this.source, this.cvrNrType)
    this.registerType(this.source, this.pNrType)
  }

  async asyncFetchData(query) {
    switch (query.type) {
      case 'collapse': {
        return this.createNewQueriesFromGoal(query)
      }
      case 'cut':
      case 'no-cut':
      case 'list': {
        let results = await this.queryDataTank(query)
        let queryResult = this.createQueryResult()
        if (results.virksomheder.length > 0)
          this.addItemsToQueryResult(results.virksomheder, this.cvrNrType, query, queryResult)
        if (results.produktionsenheder.length > 0)
          this.addItemsToQueryResult(results.produktionsenheder, this.pNrType, query, queryResult)
        return queryResult
      }
    }
  }

  addItemsToQueryResult(items, resultType, query, queryResult) {
    let count = items.length
    if (count > 0 && items[0]["hits_found"] && items[0]["hits_found"] != 9999)
      count = items[0]["hits_found"]
    if (["list", "cut", "no-cut"].indexOf(query.type) !== -1) {
      let hitsShown = (count === 1) ? 1 : (query.type === 'no-cut' && count > query.limit) ? 0 : Math.min(count, query.limit)
      for (let item of items.slice(0, hitsShown)) {
        resultType.itemFunction(item, queryResult)
      }
      if ( count > hitsShown && ["no-cut", "cut"].indexOf(query.type) !== -1 ) {
        let newQuery = queryResult.addNewQuery(this.source, resultType.id, resultType.plural, null, query.queryString, null, null)
        if (typeof resultType.iconURI !== 'undefined')
          newQuery.image = resultType.iconURI
        else if (typeof this.iconURI !== 'undefined')
          newQuery.image = this.iconURI
      }
    } else {
      if (count > 0) {
        let newQuery = queryResult.addNewQuery(this.source, resultType.id, resultType.plural, null, query.queryString, null, null)
        if (typeof resultType.iconURI !== 'undefined')
          newQuery.image = resultType.iconURI
        else if (typeof this.iconURI !== 'undefined')
          newQuery.image = this.iconURI
      }
    }
  }

  addCvrnrItemToQueryResult(item, queryResult=this.createQueryResult()) {
    if (item) {
      let geometry = item.geometri
      if (geometry)
        geometry.crs = {
          "type": "name",
          "properties": {
            "name": "epsg:25832"
          }
        }
      let result = queryResult.addResult(this.source, this.cvrNrType.id, item.navn, item.adgangsadressebetegnelse, geometry, item)
      result.id = item.cvrnummer
      result.image = this.cvrNrType.iconURI
      return result
    }
  }

  addPnrItemToQueryResult(item, queryResult=this.createQueryResult()) {
    if (item) {
      let geometry = item.geometri
      if (geometry)
        geometry.crs = {
          "type": "name",
          "properties": {
            "name": "epsg:25832"
          }
        }
      let result = queryResult.addResult(this.source, this.pNrType.id, item.navn, item.adgangsadressebetegnelse, geometry, item)
      if (!item.husnummer)
        result.isComplete = false
      result.id = item.pnummer
      result.image = this.pNrType.iconURI
      return result
    }
  }

  async completeResult(result) {
    if (result.isComplete) {
      return result
    } else {
      if (result.typeId == this.pNrType.id) {
        return this.get(result.id, this.pNrType.id)
      }
      result.isComplete = true
      return result
    }
  } 

  createNewQueriesFromGoal(query) {
    let queryResult = this.createQueryResult()
    if (query.hasTarget ? query.target.type == this.cvrNrType.id : this.goal != this.pNrType.id)
      queryResult.addNewQuery(this.source, this.cvrNrType.id, this.cvrNrType.plural, null, query.queryString )
    if (query.hasTarget ? query.target.type == this.pNrType.id : this.goal != this.cvrNrType.id)
      queryResult.addNewQuery(this.source, this.pNrType.id, this.pNrType.plural, null, query.queryString )
    return queryResult
  }

  async sq(query) {
    let queryResult = this.createQueryResult()
    if (query.geometry) {
      let queryGeometry = reproject.reproject(query.geometry, null, "EPSG:25832")
      let wktParser = getWKTParser()
      const queryWkt = wktParser.convert(queryGeometry)
      
      let virksomhedsPromise = Promise.resolve([])
      if (query.hasTarget ? query.target.type == this.cvrNrType.id : this.goal != this.pNrType.id)
        virksomhedsPromise = this.fetcher.search("virksomhed_search", "", 500, {geometry: queryWkt})
      
      let produktionsenhedsPromise = Promise.resolve([])
      if (query.hasTarget ? query.target.type == this.pNrType.id : this.goal != this.cvrNrType.id)
        produktionsenhedsPromise = this.fetcher.search("produktionsenhed_search", "", 500, {geometry: queryWkt})
      
      await Promise.all([virksomhedsPromise, produktionsenhedsPromise])
      
      let virksomhedsItems = await virksomhedsPromise
      if (virksomhedsItems.length > 0)
        this.addItemsToQueryResult(virksomhedsItems, this.cvrNrType, {type: "list", limit: 500}, queryResult)
      
      let prroduktionsenhederItems = await produktionsenhedsPromise
      if (prroduktionsenhederItems.length > 0)
        this.addItemsToQueryResult(prroduktionsenhederItems, this.pNrType, {type: "list", limit: 500}, queryResult)
    }
    return queryResult
  }


  async queryDataTank(query) {
    try {
      let queryString = query.queryString
      if (query.isBlank)
        queryString = "a b c"
      let params = {}
      if (this.kommunekode != "*") {
        let spacedKomnumre = this.kommunekode.replace(/,/, " ").replace(/\|/, " ")
        let kommunekoder = spacedKomnumre.split(" ")
        kommunekoder = kommunekoder.filter(kode => kode.length > 1)
        kommunekoder = kommunekoder.map(kode => kode.length == 3 ? "0" + kode : kode)
        //kommunekoder={0101, 0621}
        params.kommunekoder = "{" + kommunekoder.join(",") + "}"
      }
      let virksomhedsPromise = Promise.resolve([])
      if (query.hasTarget ? query.target.type == this.cvrNrType.id : this.goal != this.pNrType.id)
        virksomhedsPromise = this.fetcher.search("virksomhed_search", queryString, query.limit + 2, params)

      let produktionsenhedsPromise = Promise.resolve([])
      if (query.hasTarget ? (query.target.type == "produktionsenhed") : (this.goal != "virksomhed"))
        produktionsenhedsPromise = this.fetcher.search("produktionsenhed_search", queryString, query.limit + 2, params)
      await Promise.all([virksomhedsPromise, produktionsenhedsPromise])

      return {virksomheder: await virksomhedsPromise, produktionsenheder: await produktionsenhedsPromise}
    } catch (e) {
      throw new Error(e)
    }
  }

  async get(id, typeId) {
    if (typeId == this.cvrNrType.id) {
      let items = await this.fetcher.get("virksomhed_search", {cvrnummer: "eq." + id})
      return this.addCvrnrItemToQueryResult(items[0])
    } else if (typeId == this.pNrType.id) {
      let items = await this.fetcher.get("produktionsenhed_search", {pnummer: "eq." + id})
      return this.addPnrItemToQueryResult(items[0])
    }
    return null
  }

  async getVirksomhederForHusnummer(hId) {
    let queryResult = this.createQueryResult()
    let items = await this.fetcher.get("virksomhed_search", {husnummer: "eq." + hId})
    for (let item of items)
      this.addCvrnrItemToQueryResult(item, queryResult)
    return queryResult
  }

  async getProduktionsenhederForHusnummer(hId) {
    let queryResult = this.createQueryResult()
    let items = await this.fetcher.get("produktionsenhed_search", {husnummer: "eq." + hId})
    for (let item of items)
      this.addPnrItemToQueryResult(item, queryResult)
    return queryResult
  }
  
  async getProduktionsEnhederForCvrNummer(cvrNummer, komnumre) {
    let queryResult = this.createQueryResult()
    let params = {cvrnummerrelation: "eq." + cvrNummer}
    if (komnumre) {
      //Fix for different separators
      let spacedKomnumre = ("" + komnumre).replace(/,/, " ").replace(/\|/, " ")
      let kommunekoder = spacedKomnumre.split(" ")
      kommunekoder = kommunekoder.filter(kode => kode.length > 1)
      kommunekoder = kommunekoder.map(kode => kode.length == 3 ? "0" + kode : kode)
      //&kommunekode=in.(0316, 0751)
      params.kommunekode = "in.(" + kommunekoder.join(",") + ")"
    }
    let items = await this.fetcher.get("produktionsenhed_search", params)
    for (let item of items)
      this.addPnrItemToQueryResult(item, queryResult)
    return queryResult
  }
  
  async getRegistreringerForHusnummer(hId) {
    let registreringer = {virksomheder: null, produktionsenheder: null}
    if (this.goal != "produktionsenhed") {
      let queryResult = this.createQueryResult()
      let items = await this.fetcher.get("virksomhed_search", {husnummer: "eq." + hId})
      for (let item of items)
        this.addCvrnrItemToQueryResult(item, queryResult)
      registreringer.virksomheder = queryResult.getResults() 
    }
    if (this.goal != "virksomhed") {
      let queryResult = this.createQueryResult()
      let items = await this.fetcher.get("produktionsenhed_search", {husnummer: "eq." + hId})
      for (let item of items)
        this.addPnrItemToQueryResult(item, queryResult)
      registreringer.produktionsenheder = queryResult.getResults()
    }
    return registreringer
  }
}